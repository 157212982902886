<template>
  <v-row class="app-invoice-preview">
    <v-col
      cols="12"
      md="9"
    >
      <v-card v-if="invoice">
        <!-- Header -->
        <v-card-text class="pa-8">
          <div class="invoice-header d-flex flex-wrap justify-space-between flex-column flex-sm-row">
            <!-- Left Content -->
            <div class="mb-8 mb-sm-0">
              <div class="d-flex align-center mb-6">
                <v-img
                  :src="organization.logo_url || require('@/assets/images/logo.png')"
                  max-height="30px"
                  max-width="30px"
                  alt="logo"
                  contain
                  class="me-3"
                ></v-img>
                <span class="text--primary font-weight-bold text-xl">
                  {{ organization.display_name }}
                </span>
              </div>
              <span class="d-block">{{ organization.name }} ({{ organization.roc_number }})</span>
              <span class="d-block">{{ organization.address }}</span>
              <span class="d-block">Tel: {{ organization.contact }}</span>
              <span class="d-block">{{ organization.website }}</span>
            </div>
            <!-- Right Content -->
            <div>
              <p class="font-weight-medium text-xl text--primary mb-4">
                Invoice #{{ invoice.invoice_number }}
              </p>
              <table class="text-right ml-0 ml-sm-auto mr-0">
                <tr>
                  <td class="pe-2">
                    Date Issued:
                  </td>
                  <td class="text-left">
                    {{ utilsService.formatDate(invoice.invoice_date) }}
                  </td>
                </tr>
                <tr>
                  <td class="pe-2">
                    Due Date:
                  </td>
                  <td class="text-left">
                    {{ utilsService.formatDate(invoice.due_date) }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <!-- Customer Details -->
        <v-card-text class="pa-8">
          <div class="d-flex">
            <div>
              <table class="w-full">
                <tr>
                  <th class="text-left pb-2">
                    Invoice To:
                  </th>
                </tr>
                <template v-if="invoice.customer">
                  <tr>
                    <td>
                      {{ invoice.customer.name }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ invoice.customer.company }}
                    </td>
                  </tr>
                  <tr v-if="invoice.customer.address">
                    <td>
                      {{ invoice.customer.address }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ invoice.customer.contact_number }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ invoice.customer.email }}
                    </td>
                  </tr>
                </template>
              </table>
            </div>
            <v-spacer></v-spacer>
            <div>
              <table class="text-right ml-auto mr-0">
                <tr>
                  <td class="pe-2">
                    Issued By:
                  </td>
                  <td class="text-left">
                    {{ invoice.issued_by }}
                  </td>
                </tr>
                <tr v-if="invoice.quotation_meta">
                  <td class="pe-2 d-none d-sm-block">
                    Quotation No:
                  </td>
                  <td class="text-left">
                    <router-link
                      class="font-weight-medium text-decoration-none d-none d-sm-block"
                      :to="{ name: 'quotation-view', params: { id: invoice.quotation_meta.id } }"
                    >
                      {{ invoice.quotation_meta.quotation_number }}
                    </router-link>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div
            v-if="invoice.quotation_meta"
            class="text-right d-flex d-block d-sm-none mt-5"
          >
            <div>
              Quotation No:
            </div>
            <router-link
              class="ml-2 font-weight-medium text-decoration-none"
              :to="{ name: 'quotation-view', params: { id: invoice.quotation_meta.id } }"
            >
              {{ invoice.quotation_meta.quotation_number }}
            </router-link>
          </div>
        </v-card-text>

        <!-- Table -->
        <v-simple-table class="purchased-items-table d-none d-sm-block">
          <template v-slot:default>
            <thead>
              <tr>
                <th>
                  ITEM
                </th>
                <th>
                  UNIT PRICE
                </th>
                <th>
                  QUANTITY
                </th>
                <th>
                  DISCOUNT
                </th>
                <th>
                  AMOUNT
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="line_item in invoice.line_items_attributes"
                :key="line_item.id"
              >
                <td class="text-no-wrap py-2">
                  {{ line_item.item.name }}<br>
                  <small class="text-wrap">{{ line_item.item.description }}</small>
                </td>
                <td class="text-no-wrap">
                  {{ line_item.price }}
                </td>
                <td>
                  {{ line_item.quantity }}
                </td>
                <td>
                  {{ line_item.discount }}
                </td>
                <td>
                  {{ line_item.amount }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <!-- table view for mobile -->
        <v-data-table
          :headers="tableSmHeaders"
          :items="invoice.line_items_attributes"
          :expanded.sync="itemExpanded"
          single-expand
          show-expand
          mobile-breakpoint="0"
          hide-default-footer
          class="fixed-action d-block d-sm-none"
        >
          <!-- Amount -->
          <template #[`item.amount`]="{item}">
            <div>${{ item.amount }}</div>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td
              class="table-expand"
              :colspan="headers.length"
            >
              <p class="mt-2">
                Unit Price: ${{ item.price }}
              </p>
              <p>Quantity: {{ item.quantity }}</p>
              <p>Discount: ${{ item.discount }}</p>
              <p
                class="text-wrap"
                v-html="item.item.description"
              ></p>
            </td>
          </template>
        </v-data-table>

        <!-- Total -->
        <v-card-text
          v-if="invoice.line_items_attributes"
          class="pa-8"
        >
          <div class="invoice-total d-flex justify-space-between flex-column flex-sm-row">
            <v-spacer></v-spacer>
            <div>
              <table class="w-full">
                <tr>
                  <td class="pe-16">
                    Subtotal:
                  </td>
                  <th class="text-right">
                    ${{ parseFloat(invoice.line_items_attributes.reduce((sum, item) => sum + (parseFloat(item.price) * item.quantity), 0)).toFixed(2) }}
                  </th>
                </tr>
                <tr>
                  <td class="pe-16">
                    Total Discount:
                  </td>
                  <th class="text-right">
                    ${{ parseFloat(invoice.line_items_attributes.reduce((sum, item) => sum + parseFloat(item.discount), 0)).toFixed(2) }}
                  </th>
                </tr>
              </table>
              <v-divider class="my-3"></v-divider>
              <table class="w-full">
                <tr>
                  <td class="pe-16">
                    Total:
                  </td>
                  <th class="text-right">
                    ${{ invoice.total.toFixed(2) }}
                  </th>
                </tr>
                <tr>
                  <td class="pe-16">
                    Amount Due:
                  </td>
                  <th class="text-right">
                    ${{ invoice.amount_due.toFixed(2) }}
                  </th>
                </tr>
              </table>
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="pa-8 d-flex">
          <p class="mb-0 font-weight-semibold mb-3 pr-3">
            Note:
          </p>
          <span v-html="invoice.note"></span>
        </v-card-text>
      </v-card>

      <v-card
        v-if="receipts"
        class="mt-5"
      >
        <v-card-title>
          Payment History
        </v-card-title>

        <v-data-table
          :headers="receiptsTableColumnHeaders"
          :items="receipts"
          :options.sync="options"
          hide-default-footer
          fixed-header
          :items-per-page="50"
          mobile-breakpoint="0"
          :loading="fetchReceiptsLoading"
          class="text-no-wrap fixed-action d-none d-sm-block"
        >
          <!-- Receipt Number -->
          <template #[`item.serial_number`]="{item}">
            <router-link
              class="font-weight-medium text-decoration-none"
              :to="{ name: 'receipt-view', params: { id: item.id } }"
            >
              {{ item.receipt_number }}
            </router-link>
          </template>

          <!-- Actions -->
          <template #[`item.actions`]="{item}">
            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="me-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item :to="{ name: 'receipt-view', params: { id: item.id } }">
                  <v-list-item-title>
                    <v-icon
                      size="20"
                      class="me-2"
                    >
                      {{ icons.mdiEyeOutline }}
                    </v-icon>
                    <span>View</span>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item :to="{ name: 'receipt-form', params: { id: item.id } }">
                  <v-list-item-title>
                    <v-icon
                      size="20"
                      class="me-2"
                    >
                      {{ icons.mdiPencilOutline }}
                    </v-icon>
                    <span>Edit</span>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item @click="receipt = item; isDuplicateDialogVisible = true">
                  <v-list-item-title>
                    <v-icon
                      size="20"
                      class="me-2"
                    >
                      {{ icons.mdiContentCopy }}
                    </v-icon>
                    <span>Duplicate</span>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item @click="receipt = item; isDownloadDialogVisible = true">
                  <v-list-item-title>
                    <v-icon
                      size="20"
                      class="me-2"
                    >
                      {{ icons.mdiDownload }}
                    </v-icon>
                    <span>Download</span>
                  </v-list-item-title>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item @click="receipt = item; isVoidDialogVisible = true">
                  <v-list-item-title>
                    <v-icon
                      size="20"
                      class="me-2"
                    >
                      {{ icons.mdiDeleteOutline }}
                    </v-icon>
                    <span>Void</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>

        <!-- table view for mobile -->
        <v-data-table
          :headers="receiptsTableSmColumnHeaders"
          :items="receipts"
          :options.sync="options"
          hide-default-footer
          fixed-header
          :items-per-page="50"
          :expanded.sync="expanded"
          single-expand
          show-expand
          mobile-breakpoint="0"
          :loading="fetchReceiptsLoading"
          class="text-no-wrap fixed-action d-block d-sm-none"
        >
          <!-- Receipt Number -->
          <template #[`item.serial_number`]="{item}">
            <router-link
              class="font-weight-medium text-decoration-none"
              :to="{ name: 'receipt-view', params: { id: item.id } }"
            >
              {{ item.receipt_number }}
            </router-link>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td
              class="table-expand"
              :colspan="headers.length"
            >
              <p class="mt-2">
                Receipt Date: {{ item.receipt_date }}
              </p>
              <p>Payment Date: {{ item.payment_date }}</p>
              <div class="d-flex">
                <v-sheet width="32vh">
                  <p
                    class="text-wrap"
                    v-html="item.note"
                  ></p>
                </v-sheet>
                <v-spacer></v-spacer>
                <div class="fixed-expand-action">
                  <v-menu
                    bottom
                    left
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        class="me-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item :to="{ name: 'receipt-view', params: { id: item.id } }">
                        <v-list-item-title>
                          <v-icon
                            size="16"
                            class="me-2"
                          >
                            {{ icons.mdiEyeOutline }}
                          </v-icon>
                          <span class="caption">View</span>
                        </v-list-item-title>
                      </v-list-item>

                      <v-list-item :to="{ name: 'receipt-form', params: { id: item.id } }">
                        <v-list-item-title>
                          <v-icon
                            size="16"
                            class="me-2"
                          >
                            {{ icons.mdiPencilOutline }}
                          </v-icon>
                          <span class="caption">Edit</span>
                        </v-list-item-title>
                      </v-list-item>

                      <v-list-item @click="receipt = item; isDuplicateDialogVisible = true">
                        <v-list-item-title>
                          <v-icon
                            size="16"
                            class="me-2"
                          >
                            {{ icons.mdiContentCopy }}
                          </v-icon>
                          <span class="caption">Duplicate</span>
                        </v-list-item-title>
                      </v-list-item>

                      <v-list-item @click="receipt = item; isDownloadDialogVisible = true">
                        <v-list-item-title>
                          <v-icon
                            size="16"
                            class="me-2"
                          >
                            {{ icons.mdiDownload }}
                          </v-icon>
                          <span class="caption">Download</span>
                        </v-list-item-title>
                      </v-list-item>

                      <v-divider></v-divider>

                      <v-list-item @click="receipt = item; isVoidDialogVisible = true">
                        <v-list-item-title>
                          <v-icon
                            size="16"
                            class="me-2"
                          >
                            {{ icons.mdiDeleteOutline }}
                          </v-icon>
                          <span class="caption">Void</span>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </div>
            </td>
          </template>
        </v-data-table>

        <!-- Duplicate dialog -->
        <v-dialog
          v-model="isDuplicateDialogVisible"
          width="500"
        >
          <v-card>
            <v-card-title class="d-flex align-center mv-4">
              Duplicate {{ receipt.receipt_number }}?
              <v-spacer></v-spacer>
              <v-btn
                icon
                small
                @click="isDuplicateDialogVisible = false"
              >
                <v-icon size="22">
                  {{ icons.mdiClose }}
                </v-icon>
              </v-btn>
            </v-card-title>

            <v-card-text>
              This receipt will be duplicated. It copy all data present in this current receipt including line items.
            </v-card-text>

            <v-card-actions>
              <v-btn
                color="primary"
                block
                :loading="duplicateLoading"
                :disabled="duplicateLoading"
                class="mt-3"
                @click="duplicateReceipt()"
              >
                Make another copy
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Download dialog -->
        <v-dialog
          v-model="isDownloadDialogVisible"
          width="500"
        >
          <v-card>
            <v-card-title class="d-flex align-center mv-4">
              Download {{ receipt.receipt_number }}?
              <v-spacer></v-spacer>
              <v-btn
                icon
                small
                @click="isDownloadDialogVisible = false"
              >
                <v-icon size="22">
                  {{ icons.mdiClose }}
                </v-icon>
              </v-btn>
            </v-card-title>

            <v-card-text>
              Export this receipt as PDF.
            </v-card-text>

            <v-card-actions>
              <v-btn
                color="primary"
                block
                :loading="downloadReceiptLoading"
                :disabled="downloadReceiptLoading"
                class="mt-3"
                @click="downloadReceipt()"
              >
                Download PDF
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Void dialog -->
        <v-dialog
          v-model="isVoidDialogVisible"
          width="500"
        >
          <v-card>
            <v-card-title class="d-flex align-center mv-4">
              Void {{ receipt.receipt_number }}?
              <v-spacer></v-spacer>
              <v-btn
                icon
                small
                @click="isVoidDialogVisible = false"
              >
                <v-icon size="22">
                  {{ icons.mdiClose }}
                </v-icon>
              </v-btn>
            </v-card-title>

            <v-card-text>
              This receipt will be voided. It won't be taken into any calculations.
            </v-card-text>

            <v-card-actions>
              <v-btn
                color="error"
                block
                :loading="voidLoading"
                :disabled="voidLoading"
                class="mt-3"
                @click="voidReceipt()"
              >
                Remove
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-card-text class="pt-5">
          <v-row>
            <v-col
              cols="4"
              lg="2"
              md="3"
            >
              <div class="d-flex align-center">
                <v-select
                  v-model="tablePagination.per"
                  :items="listLengthOptions"
                  single-line
                  outlined
                  dense
                  hide-details
                  class="invoice-list-row-selection"
                  @input="listLengthChange()"
                ></v-select>
                <div class="pl-3">
                  Rows
                </div>
              </div>
            </v-col>

            <v-spacer></v-spacer>

            <v-col
              lg="10"
              md="9"
              cols="4"
              class="d-flex justify-end"
            >
              <v-pagination
                v-model="tablePagination.current_page"
                total-visible="6"
                :length="tablePagination.total_page"
                @input="fetchInvoiceReceipts"
              ></v-pagination>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <!-- Right Column: Invoice Action -->
    <v-col
      cols="12"
      md="3"
    >
      <v-card>
        <v-card-text>
          <v-btn
            class="mb-4"
            color="primary"
            block
            :loading="downloadInvoiceLoading"
            :disabled="downloadInvoiceLoading"
            @click="downloadInvoice"
          >
            Download
          </v-btn>
          <v-btn
            class="mb-4"
            color="primary"
            block
            :loading="reminderLoading"
            :disabled="reminderLoading"
            @click="remindInvoice"
          >
            Send Via Email
          </v-btn>
          <v-btn
            class="mb-4"
            color="secondary"
            block
            outlined
            :to="{ name: 'invoice-form', params: { id: $route.params.id } }"
          >
            Edit Invoice
          </v-btn>
          <v-btn
            color="secondary"
            block
            outlined
            :to="{ name: 'receipt-form', params: { invoice_id: invoice.id } }"
          >
            Create Receipt
          </v-btn>
        </v-card-text>
      </v-card>

      <ActivityLog :activities="activities" />
    </v-col>
  </v-row>
</template>

<script>
import {
  ref,
  onMounted,
  computed,
  inject,
} from '@vue/composition-api'
import { useRouter } from '@core/utils'
import {
  mdiContentCopy,
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiPrinter,
  mdiClose,
  mdiDotsVertical,
  mdiEyeOutline,
  mdiDownload,
} from '@mdi/js'
import { saveAs } from 'file-saver'
import ActivityLog from '@/components/activity/ActivityLog'

export default {
  components: {
    ActivityLog,
  },
  setup() {
    // Initializers
    const store = inject('store')
    const snackbarService = inject('snackbarService')
    const utilsService = inject('utilsService')
    const { route } = useRouter()
    const invoiceId = route.value.params.id
    const organizationData = computed(() => store.state.organization)

    // Properties
    const invoice = ref({})
    const receipts = ref([])
    const receipt = ref({})
    const organization = ref({ ...organizationData.value })
    const downloadInvoiceLoading = ref(false)
    const reminderLoading = ref(false)
    const fetchReceiptsLoading = ref(false)

    const isVoidDialogVisible = ref(false)
    const voidLoading = ref(false)
    const isDuplicateDialogVisible = ref(false)
    const duplicateLoading = ref(false)
    const isDownloadDialogVisible = ref(false)
    const downloadReceiptLoading = ref(false)

    const activities = ref([])

    // Table Handlers
    const expanded = ref([])
    const itemExpanded = ref([])
    const options = ref({
      sortBy: ['serial_number'],
      sortDesc: [true],
    })
    const listLengthOptions = ref([5, 25, 50])
    const tablePagination = ref({
      current_page: 1,
      per: 5,
      total_count: 0,
      total_page: 0,
    })
    const receiptsTableColumnHeaders = [
      { text: 'RECEIPT NO.', value: 'serial_number' },
      { text: 'RECEIPT DATE', value: 'receipt_date' },
      { text: 'PAYMENT DATE', value: 'payment_date' },
      { text: 'PAYMENT AMOUNT', value: 'payment_amount' },
      { text: 'NOTES', value: 'note', sortable: false },
      {
        text: 'ACTIONS',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ]
    const receiptsTableSmColumnHeaders = [
      { text: 'RECEIPT NO.', value: 'serial_number' },
      { text: 'PAYMENT AMOUNT', value: 'payment_amount' },
      { text: '', value: 'data-table-expand' },
    ]
    const tableSmHeaders = [
      { text: 'ITEM', value: 'item.name' },
      { text: 'AMOUNT', value: 'amount', sortable: false },
      { text: '', value: 'data-table-expand' },
    ]

    // Methods
    const fetchInvoice = () => {
      store
        .dispatch('invoiceStore/fetchInvoice', { id: invoiceId })
        .then(response => {
          invoice.value = response.data.data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching items. Please refresh!')
        })
    }
    const fetchInvoiceReceipts = () => {
      fetchReceiptsLoading.value = true
      store
        .dispatch('invoiceStore/fetchInvoiceReceipts', {
          id: invoiceId,
          page: tablePagination.value.current_page,
          per: tablePagination.value.per,
          sort_by: options.value.sortBy[0],
          sort_dir: options.value.sortDesc[0] ? 'desc' : 'asc',
        })
        .then(response => {
          const { data, pagination } = response.data
          receipts.value = data
          tablePagination.value = pagination
          fetchReceiptsLoading.value = false
        })
        .catch(error => {
          fetchReceiptsLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching receipts. Please refresh!')
        })
    }
    const downloadInvoice = () => {
      downloadInvoiceLoading.value = true
      store
        .dispatch('invoiceStore/downloadInvoice', { id: invoiceId })
        .then(response => {
          saveAs(response, `${invoice.value.invoice_number}.pdf`)
          downloadInvoiceLoading.value = false
        })
        .catch(error => {
          downloadInvoiceLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while downloading invoice. Please refresh!')
        })
    }
    const duplicateReceipt = () => {
      duplicateLoading.value = true
      store
        .dispatch('receiptStore/duplicateReceipt', { id: receipt.value.id })
        .then(response => {
          snackbarService.success(response.data.message)
          fetchInvoiceReceipts()
          isDuplicateDialogVisible.value = false
          duplicateLoading.value = false
        })
        .catch(error => {
          duplicateLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while duplicating receipt. Please refresh!')
        })
    }
    const downloadReceipt = () => {
      downloadReceiptLoading.value = true
      store
        .dispatch('receiptStore/downloadReceipt', { id: receipt.value.id })
        .then(response => {
          saveAs(response, `${receipt.value.receipt_number}.pdf`)
          isDownloadDialogVisible.value = false
          downloadReceiptLoading.value = false
        })
        .catch(error => {
          downloadReceiptLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while downloading receipt. Please refresh!')
        })
    }
    const remindInvoice = () => {
      reminderLoading.value = true
      const invoiceIds = [invoice.value.id]
      store
        .dispatch('invoiceStore/remindInvoice', { invoice_ids: invoiceIds })
        .then(response => {
          snackbarService.success(response.data.message)
          reminderLoading.value = false
        })
        .catch(error => {
          reminderLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while sending email reminder. Please refresh!')
        })
    }
    const voidReceipt = () => {
      voidLoading.value = true
      store
        .dispatch('receiptStore/voidReceipt', { id: receipt.value.id })
        .then(response => {
          snackbarService.success(response.data.message)
          fetchInvoiceReceipts()
          isVoidDialogVisible.value = false
          voidLoading.value = false
        })
        .catch(error => {
          voidLoading.value = false
          snackbarService.error(error.data.message || 'Something went wrong while voiding receipt. Please refresh!')
        })
    }
    const fetchActivities = () => {
      store
        .dispatch('invoiceStore/fetchActivities', { id: invoiceId })
        .then(response => {
          activities.value = response.data.data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching activities. Please refresh!')
        })
    }

    // Mounted
    onMounted(() => {
      fetchInvoice()
      fetchInvoiceReceipts()
      fetchActivities()
    })

    return {
      // Initializers
      utilsService,

      // Properties
      invoice,
      receipts,
      receipt,
      organization,
      downloadInvoiceLoading,
      reminderLoading,
      fetchReceiptsLoading,

      isVoidDialogVisible,
      voidLoading,
      isDuplicateDialogVisible,
      duplicateLoading,
      isDownloadDialogVisible,
      downloadReceiptLoading,
      activities,

      // Table Handlers
      expanded,
      itemExpanded,
      options,
      listLengthOptions,
      tablePagination,
      receiptsTableColumnHeaders,
      receiptsTableSmColumnHeaders,
      tableSmHeaders,

      // Icons
      icons: {
        mdiContentCopy,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiPrinter,
        mdiClose,
        mdiDotsVertical,
        mdiEyeOutline,
        mdiDownload,
      },

      // Methods
      fetchInvoiceReceipts,
      downloadInvoice,
      duplicateReceipt,
      downloadReceipt,
      remindInvoice,
      voidReceipt,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/invoice.scss';
</style>
